
const AlbumCover = ({ file, group }) => {
	const image = require("../img/" + file)
	console.log(image)
	return(
		<img className="album" src={ image } alt={ group + ' Album Cover' } />
	)
}


export default AlbumCover