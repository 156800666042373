const songList = [
	{
		title: 'November Rain',
		group: 'Guns N Roses',
		image: 'guns-n-roses.jpg',
		file: 'November-Rain.mp3',
		color: 'blue'
	},
	{
		title: 'Eruption',
		group: 'Van Halen',
		image: 'van-halen.jpg',
		file: 'Eruption.mp3',
		color: 'orange'
	},
	{
		title: 'Immigrant Song',
		group: 'Led Zeppelin',
		image: 'led-zeppelin.jpg',
		file: 'Immigrant-Song.mp3',
		color: 'green'
	},
	{
		title: 'Wind of Change',
		group: 'Scorpions',
		image: 'scorpions.jpg',
		file: 'Wind-Of-Change.mp3',
		color: 'pink'
	},
	{
		title: 'Smoke on the Water',
		group: 'Deep Purple',
		image: 'deep-purple.jpg',
		file: 'Smoke-On-The-Water.mp3',
		color: 'purple'
	},
	{
		title: 'High Hopes',
		group: 'Pink Floyd',
		image: 'pink-floyd.jpg',
		file: 'High-Hopes.mp3',
		color: 'brown'
	},
	{
		title: 'Gnarf',
		group: 'Paranoïde',
		image: 'gnarf.jpg',
		file: "Gnarf.mp3",
		color: 'blue'
	},
	{
		title: 'Parano Funk',
		group: 'Paranoïde',
		image: 'gnarf.jpg',
		file: 'ParanoFunk.mp3',
		color: 'orange'
	},
	{
		title: 'Le lapin sacré',
		group: 'Paranoïde',
		image: 'gnarf.jpg',
		file: 'Le-Lapin-Sacré.mp3',
		color: 'green'
	},
	{
		title: 'Concerto sans titro',
		group: 'Paranoïde',
		image: 'gnarf.jpg',
		file: 'Concerto-Sans-Titro.mp3',
		color: 'pink'
	},
	{
		title: 'Super Keupon',
		group: 'Paranoïde',
		image: 'gnarf.jpg',
		file: 'SuperKeupon.mp3',
		color: 'purple'
	},
	{
		title: "L'hymne aux 36 tonnes" ,
		group: 'Paranoïde',
		image: 'gnarf.jpg',
		file: "L'Hymne-Aux-36T.mp3",
		color: 'brown'
	},
	{
		title: 'Illusion',
		group: 'Paranoïde',
		image: 'gnarf.jpg',
		file: "Illusion.mp3",
		color: 'blue'
	},
	{
		title: 'Entre tes mains',
		group: 'Paranoïde',
		image: 'gnarf.jpg',
		file: 'Entre-Tes-Mains.mp3',
		color: 'orange'
	},
	{
		title: 'Quelque part dans la haine',
		group: 'Paranoïde',
		image: 'gnarf.jpg',
		file: 'Quelque-Part-Dans-La-Haine.mp3',
		color: 'green'
	},
	{
		title: "Légalisez l'égalité",
		group: 'Paranoïde',
		image: 'gnarf.jpg',
		file: "Légalisez-L'Egalité.mp3",
		color: 'pink'
	},
	{
		title: "Blouse" ,
		group: 'Paranoïde',
		image: 'gnarf.jpg',
		file: "Blouse.mp3",
		color: 'purple'
	},
	{
		title: 'Les adultes',
		group: 'Paranoïde',
		image: 'paranoide.jpg',
		file: "Les-Adultes.mp3",
		color: 'brown'
	},
	{
		title: 'Inconvenance Constitutionnelle',
		group: 'Paranoïde',
		image: 'paranoide.jpg',
		file: 'Inconvenance-Constitutionnelle.mp3',
		color: 'orange'
	}
]

export default songList
