const Cassette = ({ label, color, playing }) => {
	return(
		<div className="cassette">
			<div className="screw track screw-tl"><span></span></div>
			<div className="screw track screw-tr"><span></span></div>
			<div className="screw track screw-bl"><span></span></div>
			<div className="screw track screw-br"><span></span></div>
			<div className={ "label " + color }>
				<div className="label-writing">{ label }</div>
			</div>
			<div className="wheels-wrapper">
				<div className="wheel-shadow wheel-shadow-left"></div>
				<div className="wheel-shadow wheel-shadow-right"></div>
				<div className={"wheel wheel-left" + (playing ? ' playing' : '')}>
					<div className="teeth-wrapper">
						<div className="tooth"></div>
						<div className="tooth"></div>
						<div className="tooth"></div>
						<div className="tooth"></div>
					</div>
				</div>
				<div className="window">
					<div className="tape-left"></div>
					<div className="tape-right"></div>
				</div>
				<div className={"wheel wheel-right" + (playing ? ' playing' : '')}>
					<div className="teeth-wrapper">
						<div className="tooth"></div>
						<div className="tooth"></div>
						<div className="tooth"></div>
						<div className="tooth"></div>
					</div>
				</div>
			</div>
			<div className="bottom">
				<div className="holes">
					<div className="hole"></div>
					<div className="hole-square left"></div>
				</div>
				<div className="screw track screw-bottom"><span></span></div>
				<div className="holes">
					<div className="hole-square right"></div>
					<div className="hole"></div>
				</div>
			</div>
		</div>
	)
}

export default Cassette
