import { useState, useEffect, useRef } from 'react'
import './App.css'

import Cassette from './components/Cassette'
import AlbumCover from './components/AlbumCover'
import Player from './components/Player'

import songList from './songs'

const request = require('superagent')
const url = require('url')



// request.get('https://storage.cloud.google.com/songsbucket').end((err, res) => {
// 	console.log(res.text)
// })

function App() {
	const audioplayer = useRef(null)
	const max = songList.length - 1
	const [index, setIndex] = useState(0)
	const [playing, setPlaying] = useState(false)
	const [isPlay, setIsPlay] = useState(false)
	const [isPause, setIsPause] = useState(false)
	const [isStop, setIsStop] = useState(false)
	const [isForward, setIsForward] = useState(false)
	const [isBackward, setIsBackward] = useState(false)

	const play = () => {
		audioplayer.current?.play()
		setIsPlay(true)
		setIsPause(false)
		setIsStop(false)
		setIsForward(false)
		setIsBackward(false)
		if(playing) return
		setPlaying(true)
	}
	const pause = () => {
		audioplayer.current.pause()
		setIsPause(true)
		setIsPlay(false)
		setIsStop(false)
		setIsForward(false)
		setIsBackward(false)
		if(!playing) return
		setPlaying(false)
	}
	const previous = (e) => {
		e.preventDefault()
		setIsBackward(true)
		setIsForward(false)
		setIsPause(false)
		setIsPlay(false)
		setIsStop(false)
		setPlaying(false)
		if(index > 0) {
			setIndex(index - 1)
		} else {
			setIndex(max)
		}
		setTimeout(() => {
			setIsBackward(false)
		}, 300)
	}
	const next = (e) => {
		e.preventDefault()
		setIsForward(true)
		setIsBackward(false)
		setIsPause(false)
		setIsPlay(false)
		setIsStop(false)
		setPlaying(false)
		if(index < max) {
			setIndex(index + 1)
		} else {
			setIndex(0)
		}
		setTimeout(() => {
			setIsForward(false)
		}, 300)
	}
	const stop = () => {
		audioplayer.current.load()
		setIsStop(true)
		setIsPause(false)
		setIsPlay(false)
		setIsForward(false)
		setIsBackward(false)
		if(!playing) return
		setPlaying(false)
	}
	let songFile = {}
	const song = songList[index]
	const getSong = () => {
		if(song) {
			songFile = url.resolve('https://storage.googleapis.com/songsbucket/', song.file)
			// songFile.setHeader('Content-Type', 'text/html')
			// console.log(songFile)
		}
	}
	// useEffect(() => {
		getSong()
	// }, [])
	useEffect(() => {
		getSong()
	}, [song])
	return (
		<div className="App">
			<div className="title">Lecteur de cassettes</div>
			<Player
			play={ play }
			pause={ pause }
			backward={ e => previous(e) }
			forward={ e => next(e) }
			stop={ stop }
			isPlay={ isPlay }
			isPause={ isPause }
			isStop={ isStop }
			isBackward={ isBackward }
			isForward={ isForward }
			song={ songFile }
			playing={ playing }
			audioplayer={ audioplayer }
			/>
			<div className="main">
				<Cassette playing={ playing } label={ song.group + ' - ' + song.title } color={ song.color }/>
				<AlbumCover file={ song.image } group={ song.group } />
			</div>
		</div>
	)
}

export default App
