import React, { useEffect, useState, useRef } from 'react'
// import ProgressBar from './ProgressBar'
const Player = ({ play, pause, backward, forward, stop, isPlay, isPause, isStop, isBackward, isForward, song, playing, audioplayer }) => {
	const [progressBarWidth, setProgressBarWidth] = useState('0px')
	const [manualSetting, setManualSetting] = useState(false)
	const [volume, setVolume] = useState(2)
	const progress = useRef(null)
	const resetProgressBar = () => {
		setProgressBarWidth('0')
	}
	const startSetProgress = (e) => {
		setManualSetting(true)
		setProgress(e)
	}
	const stopSetProgress = (e) => {
		setManualSetting(false)
		setProgress(e)
	}
	const volumeUp = () => {
		if(audioplayer.current.volume < 0.9) {
			audioplayer.current.volume += 0.1
			audioplayer.current.volume = audioplayer.current.volume.toFixed(1)
			setVolume(audioplayer.current.volume * 10)
		}
	}
	const volumeDown = () => {
		if(audioplayer.current.volume > 0.1) {
			audioplayer.current.volume -= 0.1
			audioplayer.current.volume = audioplayer.current.volume.toFixed(1)
			setVolume(audioplayer.current.volume * 10)
		}
	}
	// console.log('Current time: ', audioplayer.current?.currentTime)
	// console.log('Duration: ', audioplayer.current?.duration)
	const cur = () => audioplayer.current?.currentTime
	const dur = () => audioplayer.current?.duration
	const setProgress = (e) => {
		e.preventDefault()
		resetProgressBar()
		// console.log('Event ClientX', e.clientX )
		// console.log('Event Target OffsetLeft',  e.target.offsetLeft)
		// console.log('Progress Current OffsetLeft', progress.current.offsetLeft)
		// console.log('Progress Current ClientWidth', progress.current.clientWidth)

		setProgressBarWidth((e.clientX - e.target.offsetLeft) + 'px')

		audioplayer.current.currentTime = dur() / 200 * (e.clientX - e.target.offsetLeft)
		return
	}
	useEffect(() => {
		if(audioplayer && audioplayer.current) {
			audioplayer.current.load()
			// resetProgressBar()
			if(audioplayer.current.volume === 1) {
				audioplayer.current.volume -= 0.8
				audioplayer.current.volume.toFixed(0)
			}
		}
	}, [song])

	const reloadTime = setInterval(() => {
		cur()
		setProgressBarWidth(((cur() / dur()) * 200).toFixed(0) + 'px')
	}, 200)
	useEffect(() => {
		resetProgressBar()
		if(audioplayer.current.played) {
			setProgressBarWidth(((cur() / dur()) * 200).toFixed(0) + 'px')
		} else {
			clearInterval(reloadTime)
		}
	}, [cur()])
	useEffect(() => {
		resetProgressBar()
	}, [isStop, isForward, isBackward])
	return(
		<div className="controls">
		<div className="player" >
			<button className={ "control" + (isPlay ? " pushed" : "") } onClick={ (e) => play(e) } >
				<i className="fa fa-play" aria-hidden="true" ></i>
			</button>
			<button className={ "control" + (isPause ? " pushed" : "") }  onClick={ (e) => pause(e) }>
				<i className="fa fa-pause" aria-hidden="true"></i>
			</button>
			<button className={ "control" + (isBackward ? " pushed" : "") } onClick={ (e) => backward(e) }>
				<i className="fa fa-fast-backward" aria-hidden="true" ></i>
			</button>
			<button className={ "control" + (isForward ? " pushed" : "") } onClick={ (e) => forward(e) }>
				<i className="fa fa-fast-forward" aria-hidden="true"></i>
			</button>
			<button className={ "control" + (isStop ? " pushed" : "") } onClick={ (e) => stop(e) }>
				<i className="fa fa-stop" aria-hidden="true"></i>
			</button>
			<div
			className="progress"
			onClick={ setProgress }
			>
				{/*<ProgressBar value={ progressBarWidth }/>*/}
				<div ref={ progress } className="bar" >
				<div style={{ width: progressBarWidth }} className="indicator" ></div>
				</div>
			</div>
		</div>
		<audio ref={ audioplayer } autoPlay={ false } controlsList="nofullscreen nodownload noremoteplayback novolume noplay" >
			<source src={ song } type="audio/mpeg"/>
		</audio>
		<div className="volume">
			<button className="vol-plus" onClick={ volumeUp }><i className="fa fa-volume-up" aria-hidden="true"></i></button>
			<p>{ volume.toFixed(0) }</p>
			<button className="vol-minus" onClick={ volumeDown }><i className="fa fa-volume-down" aria-hidden="true"></i></button>
		</div>
		</div>
	)
}

export default Player